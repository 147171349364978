@charset "UTF-8";
.icon-filter {
  width: 62px;
  height: 62px;
}

.icon-governance {
  width: 78px;
  height: 86px;
}

.icon-ground-engineering {
  width: 181.42px;
  height: 181.43px;
}

.icon-high-tech {
  width: 207px;
  height: 207px;
}

.icon-infrastructure {
  width: 165.51px;
  height: 165.52px;
}

.icon-menu {
  width: 23px;
  height: 17px;
}

.icon-power {
  width: 203.28px;
  height: 203.29px;
}

.icon-real-estate {
  width: 236.68px;
  height: 236.66px;
}

.icon-square {
  width: 999.29px;
  height: 999.34px;
}

.icon-up {
  width: 982.96px;
  height: 983.01px;
}

.icon-up-transp {
  width: 982.96px;
  height: 983.01px;
}

.icon-сonstruction {
  width: 224px;
  height: 223.98px;
}
